import React, { FC, ReactElement } from "react";
import { Link } from "react-router-dom";

export const HangtagsDashBoardPage: FC = (): ReactElement => {
  return (
    <div>
      <h1>吊牌类首页</h1>
      <nav>
        <ul>
          <li>
            <Link to="/all_pages_dash_board/for_hangtags/test">测试页面</Link>
          </li>
          <li>
            <Link to="/all_pages_dash_board/for_hangtags/anti_bacteria">
              5A 抗菌
            </Link>
          </li>
          <li>
            <Link to="/all_pages_dash_board/for_hangtags/warm_superior_velvet">
              暖优绒
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};
