import { Route, RouteProps, Routes } from "react-router-dom";
import { HangtagsDashBoardPage } from "./dash_board";
import { AntiBacteriaPage } from "../anti_bacteria";
import { TestPage } from "../test";
import { WarmSuperiorVelvetPage } from "../warm_superior_velvet";

export const HangtagsRoutes: React.FC<RouteProps> = () => {
  return (
    <Routes>
      <Route path="/" element={<HangtagsDashBoardPage />} />
      <Route path="/anti_bacteria" element={<AntiBacteriaPage />} />
      <Route path="/test" element={<TestPage />} />
      <Route
        path="/warm_superior_velvet"
        element={<WarmSuperiorVelvetPage />}
      />
    </Routes>
  );
};
