import React, { FC, ReactElement } from "react";
import { LoadingPage } from "./componets/loading";
import { useS3Image } from "../../utils/use_s3_image";

export const WarmSuperiorVelvetPage: FC = (): ReactElement => {
  const { imgSrc, loading } = useS3Image(
    "hangtags/warm_superior_velvet/warm_superior_velvet.jpg"
  ); // use the custom hook

  return (
    <div>
      {loading ? (
        <LoadingPage /> // display loading text while fetching
      ) : (
        <img
          src={imgSrc} // use state variable here
          style={{ maxWidth: "100%", height: "auto" }}
          alt="暖优绒"
        ></img>
      )}
    </div>
  );
};
