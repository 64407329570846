import { useState, useEffect } from "react";
import { s3_get_object } from "./aws_s3";

// Custom hook for fetching an image from S3
export const useS3Image = (key: string) => {
  const [imgSrc, setImgSrc] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchImage = async () => {
      try {
        setLoading(true);
        const response = await s3_get_object({ key });
        const byteArray = await response.Body?.transformToByteArray();
        const blob = new Blob([byteArray], { type: "image/jpeg" });
        const url = URL.createObjectURL(blob);
        setImgSrc(url);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchImage();
  }, [key]); // Dependency on 'key' so it will refetch if key changes

  return { imgSrc, loading };
};
