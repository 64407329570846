import React, { FC, ReactElement, useState, useEffect } from "react";
import { s3_get_object } from "../../utils/aws_s3";

export const TestPage: FC = (): ReactElement => {
  const [videoSrc, setVideoSrc] = useState(""); // initialize state for video source
  const [loading, setLoading] = useState(true); // initialize loading state

  useEffect(() => {
    // define a function that fetches the video
    const fetchVideo = async () => {
      try {
        setLoading(true); // start loading
        const response = await s3_get_object({
          key: "hangtags/test/test.mp4", // make sure to change this to your video file key
        });
        // Transform the response body to a byte array
        const byteArray = await response.Body?.transformToByteArray();

        // Create a blob from the byte array
        const blob = new Blob([byteArray], { type: "video/mp4" }); // note the mime type change

        // Create an object URL for the blob
        const url = URL.createObjectURL(blob);

        // Set the video source
        setVideoSrc(url);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false); // stop loading regardless of success or failure
      }
    };

    fetchVideo(); // execute the function
  }, []); // empty dependency array means this effect runs once on component mount

  return (
    <div>
      {loading ? (
        <p>Loading...</p> // display loading text while fetching
      ) : (
        <video
          src={videoSrc} // use state variable here
          style={{ maxWidth: "100%", height: "auto" }}
          controls
          autoPlay={true}
          muted={true}
        >
          Your browser does not support the video tag.
        </video>
      )}
    </div>
  );
};
