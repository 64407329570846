import { LoadingPage } from "pages/for_hangtags/componets/loading";
import { useS3Image } from "utils/use_s3_image";
import React, { FC, ReactElement } from "react";

export const SpringFestivalHolidayNotice2024: FC = (): ReactElement => {
  const { imgSrc, loading } = useS3Image(
    "hr/2024/spring_festival/holiday_notice.png"
  ); // use the custom hook

  return (
    <div>
      {loading ? (
        <LoadingPage /> // display loading text while fetching
      ) : (
        <img
          src={imgSrc} // use state variable here
          style={{ maxWidth: "100%", height: "auto" }}
          alt="暖优绒"
        ></img>
      )}
    </div>
  );
};
