import React, { FC, ReactElement } from "react";

import { Link } from "react-router-dom";

export const AllPagesDashBoard: FC = (): ReactElement => {
  return (
    <div>
      <h1>所有页面</h1>
      <nav>
        <ul>
          <li>
            <Link to="/all_pages_dash_board/for_hangtags">吊牌类页面</Link>
          </li>
          <li>
            <Link to="/all_pages_dash_board/hr/2024/spring_festival/holidy_notice">
              2024年春节放假通知
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};
