import React, { FC, ReactElement } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ThemeProvider, CssBaseline } from "@mui/material";
import { customTheme } from "./themes/customTheme";
import { AllPagesDashBoard } from "pages/dash_board";
import { HomePage } from "pages/home";
import { HangtagsRoutes } from "pages/for_hangtags/componets/rouotes";
import { SpringFestivalHolidayNotice2024 } from "pages/hr/2024/spring_festival/holiday_notice";

const App: FC = (): ReactElement => {
  return (
    <ThemeProvider theme={customTheme}>
      <CssBaseline />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/all_pages_dash_board" element={<AllPagesDashBoard />} />

          <Route
            path="/all_pages_dash_board/for_hangtags/*"
            element={<HangtagsRoutes />}
          />
          <Route
            path="/all_pages_dash_board/hr/2024/spring_festival/holidy_notice/*"
            element={<SpringFestivalHolidayNotice2024 />}
          />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
