import { GetObjectCommand, S3Client,S3ClientConfig} from "@aws-sdk/client-s3";
const config: S3ClientConfig = {
    credentials: {
        accessKeyId: "AKIAUAMTKS35WLYXSL22",
        secretAccessKey: "ey73eevOcn3FDI7EaKU7t3aHkQosSR/jcdJJj7IN",
    },
  region: 'cn-northwest-1', // your AWS region
};
const client = new S3Client(config);
interface GetS3ImageParams {
  bucket?: string;
  key: string;
}
export const s3_get_object = async ({ bucket = "static-labull-org-cn-static-files-bucket", key }:GetS3ImageParams): Promise<any> => {
  const command = new GetObjectCommand({
      Bucket: bucket,
      //   "hangtags/anti_bacteria/5a.jpg",
      Key:key,
  });
    

  try {
    const response = await client.send(command);
      return response;
  } catch (err) {
    console.error(err);
  }
};
